<template>
  <div class="order">
    <div class="dataSearchDelayTips">
      {{ $t("tips.dataSearchDelay") }}
    </div>
    <div class="panel-title">
      {{ $t("orderManage") }}
    </div>

    <div class="search-query-container">
      <div class="search-query">
        <el-form :form="searchForm" data-desc="父企业" v-if="this.userInfo.isMasterEnterprise">
          <el-row :gutter="20">
            <el-col>
              <el-form-item :label="$t('orderDateRange')">
                <search-date class="search-date" :data.sync="searchForm.searchDate" style="width: 100%;" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('enterprise')">
                <el-select v-model="searchForm.entList" @change="entChange" filterable clearable style="width: 100%;">
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option
                    v-for="item in slaveEnterprisesDatas"
                    :key="item.enterpriseCode"
                    :label="item.enterpriseName"
                    :value="item.enterpriseCode"
                  >
                    <span style="float: left">{{ item.enterpriseName }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.enterpriseCode }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('store')">
                <el-select
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  collapse-tags
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option-group v-for="group in shopList" :key="group.entId" :label="group.entDisplayName">
                    <el-option
                      v-for="item in group.storeList"
                      :key="item.storeId"
                      :label="item.storeName"
                      :value="item.storeId"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('orderTradeState')">
                <el-select v-model="searchForm.tradeState" style="width: 100%;">
                  <el-option :label="$t('all')" value=""></el-option>
                  <el-option :label="$t('payment')" value="SUCCESS"></el-option>
                  <el-option :label="$t('refund')" value="REFUND"></el-option>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('paymentOptions')">
                <el-select
                  v-model="searchForm.tradeType"
                  multiple
                  filterable
                  clearable
                  style="width: 100%;"
                  ref="tradeType"
                  :placeholder="$t('payKindHint')"
                  @change="tradeTypeChange"
                >
                  <el-option v-for="item in tradeTypes" :key="item.category" :label="item.name" :value="item.category">
                    <div class="select-option-with-images">
                      <img
                        class="option-image"
                        data-desc="tradetype = item.category; displayName = item.name"
                        :src="getPaymentLogos(item.name)"
                        :alt="item.name"
                      />
                      <span>{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('orderNo')">
                <el-input clearable v-model="searchForm.tradeRefvNo" :placeholder="$t('tradNoHint')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('terminalNo')">
                <el-input clearable v-model="searchForm.deviceNo" :placeholder="$t('terminalHint')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <el-form :form="searchForm" data-desc="子企业" v-else>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('orderDateRange')">
                <search-date class="search-date" :data.sync="searchForm.searchDate" style="width: 100%;" />
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('orderTradeState')">
                <el-select v-model="searchForm.tradeState" style="width: 100%;">
                  <el-option :label="$t('all')" value=""></el-option>
                  <el-option :label="$t('payment')" value="SUCCESS"></el-option>
                  <el-option :label="$t('refund')" value="REFUND"></el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('store')">
                <el-select
                  v-if="$settings.newStoreVersion === true"
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option v-for="item in shopList" :key="item.storeId" :label="item.storeName" :value="item.storeId">
                  </el-option>
                </el-select>
                <el-select
                  v-else
                  v-model="searchForm.shopList"
                  :placeholder="$t('tradeStroeHint')"
                  v-bind:multiple="notAll"
                  @change="changeAll"
                  filterable
                  clearable
                  style="width: 100%;"
                >
                  <el-option :label="$t('all')" value="" changed></el-option>
                  <el-option-group v-for="group in shopList" :key="group.label" :label="group.label">
                    <el-option
                      v-for="item in group.options"
                      :key="item.shopId"
                      :label="item.shopName"
                      :value="item.shopId"
                    >
                    </el-option>
                  </el-option-group>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('terminalNo')">
                <el-input clearable v-model="searchForm.deviceNo" :placeholder="$t('terminalHint')"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('orderNo')">
                <el-input clearable v-model="searchForm.tradeRefvNo" :placeholder="$t('tradNoHint')"></el-input>
              </el-form-item>
            </el-col>
            <el-col :lg="12" :md="24" :xs="24" :sm="24">
              <el-form-item :label="$t('paymentOptions')">
                <el-select
                  v-model="searchForm.tradeType"
                  multiple
                  filterable
                  clearable
                  style="width: 100%;"
                  ref="tradeType"
                  :placeholder="$t('payKindHint')"
                  @change="tradeTypeChange"
                >
                  <el-option v-for="item in tradeTypes" :key="item.category" :label="item.name" :value="item.category">
                    <div class="select-option-with-images">
                      <img
                        class="option-image"
                        data-desc="tradetype = item.category; displayName = item.name"
                        :src="getPaymentLogos(item.name)"
                        :alt="item.name"
                      />
                      <span>{{ item.name }}</span>
                    </div>
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div class="search-btn">
          <el-button class="cancel" @click="resetSearchQuery">{{ $t("searchReset") }}</el-button>
          <el-button class="confirm" @click="getList(1)" :disabled="loading">{{ $t("search") }}</el-button>
        </div>
      </div>

      <div class="summary-container" v-loading="loading">
        <div class="summary-title">
          {{ $t("tradeDataByDateRange") }}
        </div>

        <div>
          <el-row :gutter="20">
            <div class="summary-title">
              {{ $t("totalTradeOrder") }}
            </div>
            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">
                  {{ $t("tradeCount") }}
                </div>
                <div class="value">{{ numFormat(tradeData.totalCount) }} {{ $t("passage") }}</div>
              </div></el-col
            >
            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">{{ $t("totalAmount") }}</div>
                <div class="value">{{ moneyFormat(tradeData.totalAmount) }}</div>
              </div></el-col
            >

            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">{{ $t("tradeSalesCount") }}</div>
                <div class="value">{{ numFormat(tradeData.totalPayCount) }} {{ $t("passage") }}</div>
              </div></el-col
            >
            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">{{ $t("tradeSalesAmount") }}</div>
                <div class="value">{{ moneyFormat(tradeData.totalPayAmount) }}</div>
              </div></el-col
            >

            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">{{ $t("tradeRefundCount") }}</div>
                <div class="value">{{ numFormat(tradeData.totalRefundCount) }} {{ $t("passage") }}</div>
              </div></el-col
            >
            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">{{ $t("tradeRefundAmount") }}</div>
                <div class="value">{{ moneyFormat(tradeData.totalRefundAmount) }}</div>
              </div></el-col
            >
          </el-row>

          <el-row :gutter="20">
            <el-col :span="12">
              <el-row :gutter="20">
                <div class="summary-title">
                  {{ $t("totalPspTransactions") }}
                </div>
                <el-col :lg="12" :md="24" :xs="24" :sm="24"
                  ><div class="summary-item">
                    <div class="key">{{ $t("pspTradeRefundTotalCount") }}</div>
                    <div class="value">{{ numFormat(tradeData.totalPspCount) }} {{ $t("passage") }}</div>
                  </div></el-col
                >
                <el-col :lg="12" :md="24" :xs="24" :sm="24"
                  ><div class="summary-item">
                    <div class="key">{{ $t("totalAmount") }}</div>
                    <div class="value">{{ moneyFormat(tradeData.totalPspAmount) }}</div>
                  </div></el-col
                ></el-row
              ></el-col
            >

            <el-col :span="12">
              <el-row :gutter="20">
                <div class="summary-title">
                  {{ $t("totalExtTransactions") }}
                </div>
                <el-col :lg="12" :md="24" :xs="24" :sm="24"
                  ><div class="summary-item">
                    <div class="key">{{ $t("totalExtCount") }}</div>
                    <div class="value">{{ numFormat(tradeData.totalExtCount) }} {{ $t("passage") }}</div>
                  </div></el-col
                >
                <el-col :lg="12" :md="24" :xs="24" :sm="24"
                  ><div class="summary-item">
                    <div class="key">{{ $t("totalExtAmount") }}</div>
                    <div class="value">{{ moneyFormat(tradeData.totalExtAmount) }}</div>
                  </div></el-col
                >
              </el-row>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <div class="summary-title">
              {{ $t("totalPointTransactions") }}
            </div>
            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">{{ $t("tradePointUseCount") }}</div>
                <div class="value">{{ numFormat(tradeData.totalPointCount) }} {{ $t("passage") }}</div>
              </div></el-col
            >
            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">{{ $t("tradePointUseAmount") }}</div>
                <div class="value">{{ pointFormat(tradeData.totalPointAmount) }}</div>
              </div></el-col
            >
            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">{{ $t("tradePointGiveCount") }}</div>
                <div class="value">{{ numFormat(tradeData.totalPointGiveCancelCount) }} {{ $t("passage") }}</div>
              </div></el-col
            >
            <el-col :lg="6" :md="12" :xs="12" :sm="12"
              ><div class="summary-item">
                <div class="key">{{ $t("tradePointGiveAmount") }}</div>
                <div class="value">{{ pointFormat(tradeData.totalPointGiveCancelAmount) }}</div>
              </div></el-col
            >
          </el-row>
        </div>
      </div>
    </div>

    <div class="panel-sub-title">
      <div class="title">{{ tradeData.totalCount }} {{ $t("tradeCountPay") }}({{ beginCount }}~{{ endCount }})</div>

      <el-button
        class="cancel"
        :loading="btnLoading"
        :disabled="btnLoading"
        @click="downloadClick"
        icon="el-icon-download"
        v-permission="'Record.RecordDownload'"
      >
        {{ $t("recordDownload") }}
      </el-button>
    </div>

    <div class="result-background">
      <div class="result-table">
        <el-table :data="tradeData.records" :row-class-name="rowClassName" v-loading="loading" @row-click="toDetail">
          <el-table-column prop="endTime" :label="$t('dateTime')" min-width="220">
            <template slot-scope="scope">
              <i class="el-icon-time"></i>
              <span style="margin-left: 5px">{{ dateFormat(scope.row.endTime) }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="orderNo" :label="$t('orderNo')" min-width="200"></el-table-column>
          <el-table-column prop="shopName" :label="$t('store')" min-width="150"></el-table-column>
          <el-table-column prop="deviceNo" :label="$t('terminalNo')" width="150"></el-table-column>
          <el-table-column prop="tradeState" :label="$t('orderTradeState')" width="150">
            <template slot-scope="scope">
              {{ scope.row.tradeState === "REFUND" ? $t("refund") : $t("payment") }}
            </template>
          </el-table-column>

          <el-table-column prop="orderAmount" :label="$t('orderAmount')" min-width="150">
            <template slot-scope="scope">{{ moneyFormat(Number(scope.row.orderAmount)) }} </template>
          </el-table-column>

          <el-table-column :label="$t('orderPoint')" width="180">
            <template slot-scope="scope">{{ pointFormat(scope.row.pointAmount) }} </template>
          </el-table-column>
          <el-table-column class-name="remove-padding" :label="$t('tradePayKind')" width="220">
            <template slot-scope="scope">
              <div
                class="image-text-container orderline"
                v-for="(item, index) in scope.row.combinationRecords"
                :key="index"
              >
                <img
                  data-desc="tradetype = item.category; displayName = item.tradeTypeDisplayName"
                  :src="getPaymentLogos(item.tradeTypeDisplayName)"
                  :alt="item.tradeTypeDisplayName"
                />
                <span>{{ item.tradeTypeDisplayName }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column class-name="remove-padding" :label="$t('eachAmount')" width="150">
            <template slot-scope="scope">
              <div class="orderline" v-for="(item, index) in scope.row.combinationRecords" :key="index">
                {{ moneyFormat(Number(item.amount)) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column fixed="right" width="150" :label="$t('operation')">
            <template slot-scope="scope">
              <div style="text-align: center;">
                <el-button size="mini" type="text" @click.native.stop="toDetail(scope.row)">
                  {{ $t("detail") }}
                  <i class="el-icon-arrow-right"></i>
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
        background
        layout=" prev, pager, next, sizes, total"
        :total="tradeData.totalCount"
        :page-size="currentPageSize"
        :page-sizes="[10, 15, 20]"
        :current-page="currentPage"
        @current-change="currentChange"
        @size-change="sizeChange"
        class="common-pagination"
      >
      </el-pagination>
    </div>

    <side-layer :show-side-layer.sync="detailDrawer">
      <div v-loading="detailLoading">
        <div class="setting-nav-title">{{ $t("paymentInfo") }}</div>

        <div class="dataTable">
          <div class="dataItem">
            <td class="name">{{ $t("payDateTime") }}</td>
            <td class="value">
              {{ dateFormat(detailInfo.endTime) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("enterpriseCode") }}
            </td>
            <td class="value">
              {{ detailInfo.entId }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("storeCode") }}</td>
            <td class="value">
              {{ detailInfo.shopId }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("storeName") }}</td>
            <td class="value">
              {{ detailInfo.shopDisplay }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("terminalNo") }}</td>
            <td class="value">
              {{ detailInfo.deviceNo }}
            </td>
          </div>
          <div class="dataItem" v-if="detailInfo.combinationPayment">
            <td class="name">{{ $t("orderAmount") }}</td>
            <td class="value">
              {{ moneyFormat(detailInfo.orderAmount) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("orderTradeState") }}
            </td>
            <td class="value">
              {{ $t("payment") }}
            </td>
          </div>
          <div class="dataItem" v-if="detailInfo.combinationPayment || detailInfo.offlineCreditCard">
            <td class="name">{{ $t("orderNo") }}</td>
            <td class="value">
              {{ detailInfo.orderNo }}
            </td>
          </div>
          <div class="dataItem" v-if="detailInfo.combinationPayment">
            <td class="name">{{ $t("pointCard") }}</td>
            <td class="value">
              {{ detailInfo.pointCardNo }}
            </td>
          </div>
          <div class="dataItem" v-if="detailInfo.combinationPayment">
            <td class="name">{{ $t("givePoint") }}</td>
            <td class="value">
              {{ pointFormat(Number(detailInfo.pointAmount)) }}
            </td>
          </div>
        </div>

        <div class="dataTable">
          <div class="dataItem">
            <td :class="['name', detailInfo.combinationPayment ? 'solid' : '']">
              {{ $t("tradePayKind") }}
            </td>
            <td :class="['value image-text-container', detailInfo.combinationPayment ? 'solid' : '']">
              <img
                data-desc="tradetype = item.category; displayName = detailInfo.tradeTypeDisplayName"
                :src="getPaymentLogos(detailInfo.tradeTypeDisplayName)"
                :alt="detailInfo.tradeTypeDisplayName"
              />
              <span>{{ detailInfo.tradeTypeDisplayName }} </span>
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("cashCount") }}
            </td>
            <td class="value">
              {{ moneyFormat(Number(detailInfo.tradeAmount)) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("paymentNo") }}</td>
            <td class="value">
              {{ detailInfo.tradeNo }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("outTradeNo") }}</td>
            <td class="value">{{ detailInfo.outTradeNo }}</td>
          </div>
        </div>

        <div class="dataTable" v-for="item in detailInfo.combinationRecords" v-bind:key="item.outTradeNo">
          <div class="dataItem">
            <td :class="['name', detailInfo.combinationPayment ? 'solid' : '']">
              {{ $t("tradePayKind") }}
            </td>
            <td :class="['value image-text-container', detailInfo.combinationPayment ? 'solid' : '']">
              <img
                data-desc="tradetype = item.category; displayName = detailInfo.tradeTypeDisplayName"
                :src="getPaymentLogos(item.tradeTypeDisplayName)"
                :alt="item.tradeTypeDisplayName"
              />
              <span>{{ item.tradeTypeDisplayName }} </span>
            </td>
          </div>
          <div class="dataItem">
            <td class="name">
              {{ $t("cashCount") }}
            </td>
            <td class="value">
              {{ moneyFormat(Number(item.tradeAmount)) }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("paymentNo") }}</td>
            <td class="value">
              {{ item.tradeNo }}
            </td>
          </div>
          <div class="dataItem">
            <td class="name">{{ $t("outTradeNo") }}</td>
            <td class="value">
              {{ item.outTradeNo }}
            </td>
          </div>
        </div>

        <div class="setting-nav-title" v-if="detailInfo.refundRecordList && detailInfo.refundRecordList.length > 0">
          {{ $t("tradeRefundInfo") }}
        </div>
        <div class="dataTable" v-for="item in detailInfo.refundRecordList" :key="item.recordId">
          <div v-if="item.combinationPayment">
            <div class="dataItem">
              <td class="name">
                {{ $t("refundDate") }}
              </td>
              <td class="value">
                {{ dateFormat(item.beginTime) }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("terminalNo") }}
              </td>
              <td class="value">
                {{ item.deviceNo }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("orderNo") }}
              </td>
              <td class="value">
                {{ item.orderNo }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("orderTradeState") }}</td>
              <td class="value">
                {{ $t("refund") }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("orderAmount") }}
              </td>
              <td class="value">{{ moneyFormat(item.refundAmount) }}</td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("refundPoint") }}</td>
              <td class="value">
                {{ pointFormat(Number(item.pointAmount)) }}
              </td>
            </div>

            <template class="name-value" v-for="refund in item.combinationRecords">
              <div class="dataItem">
                <td class="name solid">{{ $t("tradePayKind") }}</td>
                <td class="value image-text-container solid">
                  <img
                    data-desc="tradetype = item.category; displayName = refund.tradeTypeDisplayName"
                    :src="getPaymentLogos(refund.tradeTypeDisplayName)"
                    :alt="refund.tradeTypeDisplayName"
                  />
                  <span>{{ refund.tradeTypeDisplayName }} </span>
                </td>
              </div>

              <div class="dataItem">
                <td class="name">{{ $t("cashCount") }}</td>
                <td class="value">
                  {{ moneyFormat(Number(refund.refundAmount)) }}
                </td>
              </div>
            </template>
          </div>

          <div v-else>
            <div class="dataItem">
              <td class="name">
                {{ $t("refundDate") }}
              </td>
              <td class="value">
                {{ dateFormat(item.beginTime) }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("terminalNo") }}
              </td>
              <td class="value">
                {{ item.deviceNo }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("refundNo") }}
              </td>
              <td class="value">
                {{ item.refundNo }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">{{ $t("orderTradeState") }}</td>
              <td class="value">
                {{ $t("refund") }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("tradePayKind") }}
              </td>
              <td class="value image-text-container">
                <img
                  data-desc="tradetype = item.category; displayName = item.tradeTypeDisplayName"
                  :src="getPaymentLogos(item.tradeTypeDisplayName)"
                  :alt="item.tradeTypeDisplayName"
                />
                <span>{{ item.tradeTypeDisplayName }} </span>
              </td>
            </div>
            <div class="dataItem" v-if="detailInfo.combinationPayment">
              <td class="name">{{ $t("refundPoint") }}</td>
              <td class="value">
                {{ pointFormat(Number(item.pointAmount)) }}
              </td>
            </div>
            <div class="dataItem">
              <td class="name">
                {{ $t("cashCount") }}
              </td>
              <td class="value">{{ moneyFormat(item.refundAmount) }}</td>
            </div>
          </div>
        </div>

        <div v-permission="'Refund.Refund'">
          <div v-if="detailInfo.canRefund">
            <div class="explain">
              {{ $t("tradeRefundButtonInfo") }}
            </div>
            <el-button class="delete" style="margin-top: 20px;" @click="toRefund">
              {{ $t("tradeRefund") }}
            </el-button>
          </div>
        </div>
      </div>
    </side-layer>

    <modal-dialog :show-dialog.sync="refundDialog" :dialog-title="$t('tradeRefund')">
      <div class="explain" style="text-align: center;">
        {{ $t("tradeRefundSettingInfo") }}
      </div>

      <el-form label-position="top" class="refund-form" @submit.native.prevent>
        <el-form-item v-bind:label="$t('tradeRefundMoney')">
          <el-radio-group v-model="refundType" @change="handleRefundTypeChange" class="refund-radio">
            <el-radio label="all" name="all">{{ $t("cashAll") }}</el-radio>
            <el-radio label="part" name="part" v-if="canPartRefund">
              {{ $t("tradeCountSet") }}
            </el-radio>
          </el-radio-group>

          <el-input-number
            class="refund-part-input"
            v-if="canPartRefund"
            @change="refundAmountChange"
            v-model="refundAmount"
            :controls="false"
            :max="canRefundAmount"
            :min="0"
            :precision="refundAmountPrecision"
            :placeholder="$t('PleaseEnterRefundAmount')"
            :disabled="refundType == 'all'"
          >
          </el-input-number>
        </el-form-item>

        <el-form-item :label="$t('refundDetail')">
          <table cellspacing="0" cellpadding="0" border="0" class="refund-table">
            <tr>
              <th>{{ $t("tradePaidCount") }}</th>
              <th>{{ $t("tradeRefundedCount") }}</th>
            </tr>

            <tr>
              <td>{{ moneyFormat(canRefundAmount) }}</td>

              <td>
                <div class="refund-item-container" v-for="item in customPartRefundList" :key="item.tradeNo">
                  <div class="refund-item">
                    <span>{{ $t("tradePayKind") }}：</span>

                    <img
                      data-desc="tradetype = item.tradeType ; displayName = item.tradeTypeDisplayName"
                      :src="getPaymentLogos(item.tradeTypeDisplayName)"
                      :alt="item.tradeTypeDisplayName"
                    />
                  </div>
                  <div class="refund-item">
                    <span>{{ $t("cashCount") }}：</span>
                    <span>{{ moneyFormat(item.refundFee) }}</span>
                  </div>
                </div>
              </td>
            </tr>
          </table>
        </el-form-item>
      </el-form>

      <template v-slot:footer>
        <el-button class="cancel" :disabled="refundLoading" @click="refundDialog = false">
          {{ $t("cancel") }}
        </el-button>
        <el-button
          class="confirm dialog-btn"
          type="primary"
          @click="refundSubmit"
          :loading="refundLoading"
          :disabled="refundLoading"
        >
          {{ $t("tradeChangeApply") }}
        </el-button>
      </template>
    </modal-dialog>

    <modal-dialog :show-dialog.sync="downloadDialog" :dialog-title="$t('dataDownload')" :show-footer="false">
      <div class="download-type-container">
        <div class="download-type-item" @click="downloadTradData(3)">
          <img src="~@/assets/download_type_icon/csv.svg" alt="" />
          <span>CSV</span>
        </div>
        <div class="download-type-item" @click="downloadTradData(0)">
          <img src="~@/assets/download_type_icon/excel.svg" alt="" />
          <span>Excel</span>
        </div>

        <div class="download-type-item" @click="downloadTradData(1)">
          <img src="~@/assets/download_type_icon/pdf.svg" alt="" />
          <span>PDF</span>
        </div>

        <div class="download-type-item" @click="downloadTradData(2)">
          <img src="~@/assets/download_type_icon/zip.svg" alt="" />
          <span>ZIP</span>
        </div>
      </div>
    </modal-dialog>
  </div>
</template>

<script>
import pagination from "@/mixins/pagination";
import getPaymentLogos from "@/mixins/paymentLogos";
/*
:src="getPaymentLogos(scope.row.tradeTypeDisplayName)" :alt="scope.row.tradeTypeDisplayName"
*/

import {
  tradeTypes,
  orderRefund,
  downloadOrderFile,
  orderRecordList,
  orderRecordDetail,
  multiTradeTypes
} from "api/trade";
import { shopList, storeList, slaveenterprisestorelist } from "api/shop";
import { slaveEnterprises } from "api/enterprise";
import { isArray, isUndefined, sum, cloneDeep } from "lodash";
import { moneyFormat, numFormat, pointFormat } from "utils";
import { getSessionStorage } from "@/utils/storage";
import { executeDownload } from "utils/download";
import { dateFormat, diffMonths } from "utils/date";

export default {
  name: "order",
  mixins: [pagination, getPaymentLogos],
  data() {
    return {
      slaveEnterprisesDatas: [],
      userInfo: {},
      tradeData: {
        totalCount: 0,
        totalAmount: "",
        totalPayCount: 0,
        totalPayAmount: 0,
        totalRefundCount: 0,
        totalRefundAmount: 0,
        totalPspPayCount: 0,
        totalPspPayAmount: "",
        totalPspRefundCount: 0,
        totalPspRefundAmount: "",
        totalPspCount: 0,
        totalPspAmount: "",
        totalPointPayCount: 0,
        totalPointPayAmount: 0,
        totalPointRefundCount: 0,
        totalPointRefundAmount: 0,
        totalPointCount: 0,
        totalPointAmount: 0,
        totalPointGiveCount: 0,
        totalPointGiveAmount: 0,
        totalCancelPointGiveCount: 0,
        totalCancelPointGiveAmount: 0,
        totalPointGiveCancelCount: 0,
        totalPointGiveCancelAmount: 0,
        totalExtPayCount: 0,
        totalExtPayAmount: 0,
        totalExtRefundCount: 0,
        totalExtRefundAmount: 0,
        totalExtCount: 0,
        totalExtAmount: 0,
        totalPointUseCount: 0,
        totalPointUseAmount: 0,
        records: []
      },
      tradeDataTemplate: {
        totalCount: 0,
        totalAmount: "",
        totalPayCount: 0,
        totalPayAmount: 0,
        totalRefundCount: 0,
        totalRefundAmount: 0,
        totalPspPayCount: 0,
        totalPspPayAmount: "",
        totalPspRefundCount: 0,
        totalPspRefundAmount: "",
        totalPspCount: 0,
        totalPspAmount: "",
        totalPointPayCount: 0,
        totalPointPayAmount: 0,
        totalPointRefundCount: 0,
        totalPointRefundAmount: 0,
        totalPointCount: 0,
        totalPointAmount: 0,
        totalPointGiveCount: 0,
        totalPointGiveAmount: 0,
        totalCancelPointGiveCount: 0,
        totalCancelPointGiveAmount: 0,
        totalPointGiveCancelCount: 0,
        totalPointGiveCancelAmount: 0,
        totalExtPayCount: 0,
        totalExtPayAmount: 0,
        totalExtRefundCount: 0,
        totalExtRefundAmount: 0,
        totalExtCount: 0,
        totalExtAmount: 0,
        totalPointUseCount: 0,
        totalPointUseAmount: 0,
        records: []
      },
      spanData: [],
      loading: false,
      btnLoading: false,
      totalPayAmount: 0,
      totalRefundAmount: 0,
      shopList: [],
      tradeTypes: [],
      searchForm: {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        shopList: "",
        tradeType: [],
        tradeState: "",
        deviceNo: "",
        tradeRefvNo: "",
        entList: ""
      },
      downloadDialog: false,
      detailDrawer: false,
      detailLoading: false,
      detailInfo: {},
      refundDialog: false,
      canRefundAmount: 0,
      refundAmount: 0,
      refundType: "all",
      refundAmountPrecision: 0,
      canPartRefund: true,
      refundLoading: false,
      notAll: false,
      beginCount: 0,
      endCount: 0,
      customPartRefundList: [],
      refundListMeta: [],
      refundRecordList: []
    };
  },

  methods: {
    slaveEnterprises,
    dateFormat,
    numFormat,
    pointFormat,
    diffMonths,
    moneyFormat,

    tradeTypeChange() {
      let isMobile = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );

      this.$nextTick(() => {
        if (isMobile) this.$refs["tradeType"].blur();
      });
    },

    changeAll(value) {
      if (!value[value.length - 1]) {
        this.searchForm.shopList = "";
        this.notAll = false;
      } else {
        if (isArray(value)) {
          this.searchForm.shopList = value;
        } else {
          this.searchForm.shopList = [value];
        }

        this.notAll = true;
      }
      if (this.userInfo.isMasterEnterprise) {
        this.getMultiTradeTypes();
      }
    },

    getList(pageIndex = 1, pageSize = this.currentPageSize) {
      let that = this;
      let beginTime = this.searchForm.searchDate[0];
      let endTime = this.searchForm.searchDate[1];
      this.currentPage = pageIndex;
      if (diffMonths(endTime, beginTime) > 2) {
        this.$message.warning(this.$t("tradeSearchPeriodLimit"));
      } else {
        this.loading = true;
        let data = JSON.parse(JSON.stringify(that.searchForm));
        delete data.searchDate;
        if (!isArray(data.shopList)) {
          data.shopList = [];
        }
        orderRecordList({
          pageIndex,
          pageSize,
          beginTime,
          endTime,
          ...data
        }).then(response => {
          this.loading = false;
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.tradeData = response.result;
            if (response.result.records.length) {
              that.beginCount = (pageIndex - 1) * pageSize + 1;
              that.endCount = that.beginCount + response.result.records.length - 1;
            }
          } else {
            this.tradeData = this.tradeDataTemplate;
            that.beginCount = 0;
            that.endCount = 0;
          }
        });
      }
    },

    resetSearchQuery() {
      this.searchForm = {
        searchDate: [
          this.moment()
            .startOf("day")
            .format("YYYY/MM/DD HH:mm:ss"),
          this.moment()
            .endOf("day")
            .format("YYYY/MM/DD HH:mm:ss")
        ],
        shopList: "",
        entList: "",
        tradeType: [],
        tradeState: "",
        deviceNo: "",
        tradeRefvNo: ""
      };
      this.notAll = false;
      if (this.userInfo.isMasterEnterprise) {
        this.getSlaveEnterprises();
        this.getSlaveEnterpriseStoreList();
        this.getMultiTradeTypes();
      }
    },
    // 订单详情
    toDetail(row) {
      this.detailDrawer = true;
      this.detailLoading = true;
      this.detailInfo = {};
      let { tradeType, orderNo, tradeState, outTradeNo } = row;
      // if (row.isCreditCard) {
      //   outTradeNo = orderNo;
      // }
      // 初始化自定义返金数组
      this.customPartRefundList = [];
      this.RefundList = [];
      //数据查询
      orderRecordDetail({ tradeType, orderNo, tradeState, outTradeNo }).then(response => {
        this.detailLoading = false;
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.detailInfo = response.result;
          if (!this.detailInfo.canRefund) {
            return;
          }
          this.canRefundAmount = Number(this.detailInfo.orderAmount);
          // 业务逻辑最后返金Point，顾将Point类放置到自定义数组最后
          if (this.detailInfo.combinationPayment) {
            //tradeCategory 交易类别 0 PSP 1 POINT 2 EXAT
            if (this.detailInfo.tradeCategory === 0) {
              this.customPartRefundList.push(cloneDeep(this.detailInfo));
              if (isArray(this.detailInfo.combinationRecords) && this.detailInfo.combinationRecords.length > 0) {
                this.customPartRefundList.push(cloneDeep(...this.detailInfo.combinationRecords));
              }
            } else {
              if (isArray(this.detailInfo.combinationRecords) && this.detailInfo.combinationRecords.length > 0) {
                this.customPartRefundList.push(cloneDeep(...this.detailInfo.combinationRecords));
              }
              this.customPartRefundList.push(cloneDeep(this.detailInfo));
            }
          } else {
            this.customPartRefundList.push(cloneDeep(this.detailInfo));
          }

          if (this.customPartRefundList.length > 0) {
            this.customPartRefundList.forEach(item => {
              item.tradeAmount = Number(item.tradeAmount);
            });
          }
          if (isArray(this.detailInfo.refundRecordList)) {
            //处理可返金金额
            let allAmount = 0;
            this.detailInfo.refundRecordList.forEach(item => {
              allAmount += Number(item.refundAmount);
              if (item.combinationPayment) {
                item.combinationRecords.forEach(refund => {
                  let trade = this.customPartRefundList.find(psp => psp.tradeType === refund.tradeType);
                  trade.tradeAmount = Number(trade.tradeAmount) - Number(refund.refundAmount);
                });
              } else {
                let trade = this.customPartRefundList.find(psp => psp.tradeType === item.tradeType);
                trade.tradeAmount = Number(trade.tradeAmount) - Number(item.refundAmount);
              }
            });
            this.canRefundAmount = Number(this.detailInfo.orderAmount) - Number(allAmount);
          }
          //用来重置返金
          this.refundListMeta = cloneDeep(this.customPartRefundList);
        } else {
          this.$message.error(this.$t("fail"));
        }
      });
    },
    //处理返金金额逻辑
    refundAmountChange(amount) {
      isUndefined(amount) ? (this.refundAmount = 0) : (this.refundAmount = amount);
      let value = this.refundAmount;
      let pspOrExatOrders = this.customPartRefundList.filter(r => r.tradeCategory === 0 || r.tradeCategory === 2);
      let pointOrders = this.customPartRefundList.filter(r => r.tradeCategory === 1);
      if (value === Number(this.detailInfo.orderAmount)) {
        for (let order of this.refundListMeta) {
          this.customPartRefundList.find(psp => psp.tradeNo === order.tradeNo).refundFee = order.tradeAmount;
        }
      } else if (pspOrExatOrders && pointOrders && pspOrExatOrders.length > 0 && pointOrders.length > 0) {
        let pspOrExataTotalAmount = sum(pspOrExatOrders.map(item => item.tradeAmount));
        if (value === pspOrExataTotalAmount) {
          for (let pspOrder of pspOrExatOrders) {
            this.customPartRefundList.find(psp => psp.tradeNo === pspOrder.tradeNo).refundFee = pspOrder.tradeAmount;
          }
          for (let pointOrder of pointOrders) {
            this.customPartRefundList.find(point => point.tradeNo === pointOrder.tradeNo).refundFee = 0;
          }
        } else if (value > pspOrExataTotalAmount) {
          for (let pspOrder of pspOrExatOrders) {
            this.customPartRefundList.find(psp => psp.tradeNo === pspOrder.tradeNo).refundFee = pspOrder.tradeAmount;
          }
          let balance = value - pspOrExataTotalAmount;
          let amount = 0;
          for (let pointOrder of pointOrders) {
            if (balance >= amount) {
              amount += pointOrder.tradeAmount;
              this.customPartRefundList.find(point => point.tradeNo === pointOrder.tradeNo).refundFee =
                pointOrder.tradeAmount > balance ? balance : pointOrder.tradeAmount;
            } else {
              this.customPartRefundList.find(point => point.tradeNo === pointOrder.tradeNo).refundFee =
                balance - amount > 0 ? balance - amount : 0;
              break;
            }
          }
        } else {
          for (let i = 0; i < pspOrExatOrders.length; i++) {
            if (i === 0) {
              this.customPartRefundList.find(point => point.tradeNo === pspOrExatOrders[i].tradeNo).refundFee = value;
            } else {
              this.customPartRefundList.find(point => point.tradeNo === pspOrExatOrders[i].tradeNo).refundFee = 0;
            }
          }

          for (let pointOrder of pointOrders) {
            this.customPartRefundList.find(point => point.tradeNo === pointOrder.tradeNo).refundFee = 0;
          }
        }
      } else {
        for (let item of this.customPartRefundList) {
          if (item.tradeAmount >= value) {
            item.refundFee = value;
          } else {
            item.refundFee = item.tradeAmount;
          }
        }
      }
    },

    handleRefundTypeChange(value) {
      this.refundAmount = 0;
      if (value == "all") {
        this.customPartRefundList = this.refundListMeta;
        this.customPartRefundList.forEach(item => {
          item.refundFee = Number(item.tradeAmount);
        });
      }
    },

    toRefund() {
      this.refundAmount = 0;
      this.refundType = "all";
      this.customPartRefundList.forEach(item => {
        item.refundFee = Number(item.tradeAmount);
      });

      if (this.detailInfo.offlineCreditCard) {
        this.$message.warning(this.$t("unsupportedRefund"));
        return;
      }
      this.refundDialog = true;

      if (this.detailInfo.onlyAllRefund) {
        this.canPartRefund = false;
      } else {
        if (this.detailInfo.canPartialRefund) {
          this.canPartRefund = true;
        } else {
          this.canPartRefund = false;
        }
      }

      switch (getSessionStorage("currency")) {
        case "JPY":
          this.refundAmountPrecision = 0;
          break;
        default:
          this.refundAmountPrecision = 2;
          break;
      }
    },
    //点击退款
    refundSubmit() {
      let refundInfos = this.customPartRefundList
        .filter(m => m.refundFee > 0)
        .map(item => {
          return {
            tradeType: item.tradeType,
            refundFee: item.refundFee
          };
        });
      let data = {
        orderNo: this.detailInfo.orderNo,
        outTradeNo: this.detailInfo.outTradeNo,
        shopId: this.detailInfo.shopId,
        refundInfos: refundInfos
      };
      if (this.refundType === "all") {
        data.totalRefundFee = this.canRefundAmount.toString();
      } else {
        if (this.refundAmount === 0) {
          this.$message.error(this.$t("refundFeeDataError"));
          return;
        }
        data.totalRefundFee = this.refundAmount.toString();
      }
      this.refundLoading = true;
      orderRefund(data).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.$message({
            showClose: true,
            message: this.$t("success"),
            type: "success"
          });
          this.refundDialog = false;
          this.toDetail(this.detailInfo);
          this.getList(this.currentPage);
        }
        this.refundLoading = false;
      });
    },

    rowClassName({ row }) {
      let className = "";
      if (row.tradeState === "REFUND") className += "row-refund-color";
      return className;
    },

    getShopList() {
      if (this.$settings.newStoreVersion === true) {
        storeList().then(response => {
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.shopList = response.result;
          }
        });
      } else {
        shopList().then(response => {
          if (response.statusCode === 200 && response.resultStatus === "Success") {
            this.shopList = [
              {
                label: this.$t("shopOffline"),
                options: []
              },
              {
                label: this.$t("shopOnline"),
                options: []
              }
            ];

            if (response.result.length > 0) {
              response.result.forEach(item => {
                if (item.isOnline) {
                  this.shopList[1].options.push(item);
                } else {
                  this.shopList[0].options.push(item);
                }
              });
            }
          }
        });
      }
    },

    getTradeTypes() {
      tradeTypes().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.tradeTypes = response.result;
          this.getList();
        }
      });
    },

    downloadClick() {
      this.downloadDialog = true;
    },

    downloadTradData(fileType) {
      this.downloadDialog = false;
      this.btnLoading = true;
      downloadOrderFile({
        BeginTime: this.searchForm.searchDate[0],
        EndTime: this.searchForm.searchDate[1],
        EntList: this.searchForm.entList,
        ShopList: this.searchForm.shopList,
        TradeType: this.searchForm.tradeType,
        TradeState: this.searchForm.tradeState,
        DeviceNo: this.searchForm.deviceNo,
        TradeRefvNo: this.searchForm.tradeRefvNo,
        fileFormat: fileType
      }).then(response => {
        if (response.data) {
          executeDownload(response);
          this.btnLoading = false;
          this.$message.success(this.$t("success"));
        } else {
          this.btnLoading = false;
        }
      });
    },
    // 查询多企业多店铺支付方式列表
    getMultiTradeTypes() {
      let param = { entIdList: this.searchForm.entList, storeIdList: this.searchForm.shopList };
      multiTradeTypes(param).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.tradeTypes = response.result;
        }
      });
    },
    // 获取父子企业数据
    getSlaveEnterprises() {
      slaveEnterprises().then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.slaveEnterprisesDatas = response.result;
        } else {
          this.slaveEnterprisesDatas = [];
        }
      });
    },
    // 获取子企业店铺
    getSlaveEnterpriseStoreList(entList) {
      slaveenterprisestorelist({ entList }).then(response => {
        if (response.statusCode === 200 && response.resultStatus === "Success") {
          this.shopList = response.result;
        }
      });
    },
    // 企业选择事件
    entChange(value) {
      this.searchForm.shopList = "";
      this.notAll = false;
      if (!value[value.length - 1]) {
        this.getSlaveEnterpriseStoreList();
      } else {
        this.getSlaveEnterpriseStoreList(value);
      }
      this.getMultiTradeTypes();
    }
  },

  mounted() {
    this.userInfo = JSON.parse(getSessionStorage("userInfo"));
    if (this.userInfo.isMasterEnterprise) {
      this.getSlaveEnterprises();
      this.getSlaveEnterpriseStoreList();
      this.getMultiTradeTypes();
    } else {
      this.getShopList();
      this.getTradeTypes();
    }
  }
};
</script>

<style lang="scss" scoped>
@import "~@/styles/mixin.scss";
@import "~@/styles/variables.scss";

.orderline {
  height: 36px;
  line-height: 36px;
  border-bottom: 1px solid #ebeef5;
  padding-right: -10px;

  &:last-child {
    border-bottom: none;
  }
}

::v-deep.el-table {
  .remove-padding {
    .cell {
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
